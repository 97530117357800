/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "typeface-nunito-sans";

// Import global CSS settings.
import "./src/styles/global.css";
